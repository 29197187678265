import React from 'react';
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { LoadableAfterPaint } from '@confluence/loadable';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { BannerStateContainer } from '@confluence/banners';
import { fg } from '@confluence/feature-gating';

import { Nav4PromptBannerComponent } from './Nav4PromptBannerComponent';
import { useRegisterNav4PromptBanner } from './useRegisterNav4PromptBanner';

const Nav4PromptBannerContainerComponent = ({
	bannerState,
}: {
	bannerState: BannerStateContainer;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { shouldRender, onDismiss } = useRegisterNav4PromptBanner(bannerState);

	const handleOnDismiss = () => {
		onDismiss();

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'banner',
				actionSubjectId: 'nav4PromptBanner',
				source: 'nav4PromptBanner',
				attributes: {
					isReminderBanner: fg('confluence_nav4_admin_reminder_banner'),
				},
			},
		}).fire();
	};

	return shouldRender ? <Nav4PromptBannerComponent onDismiss={handleOnDismiss} /> : null;
};

export const Nav4PromptBanner = withErrorBoundary({
	attribution: Attribution.DISCO,
})(() => (
	<Subscribe to={[BannerStateContainer]}>
		{(bannerState: BannerStateContainer) => (
			<Nav4PromptBannerContainerComponent bannerState={bannerState} />
		)}
	</Subscribe>
));

export const Nav4PromptBannerLoadable = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav4PromptBanner" */ './Nav4PromptBanner'))
			.Nav4PromptBanner,
	name: 'Nav4PromptBanner',
});
